import { cn } from '@paladise/ui/lib/utils'
import React from 'react'

interface Prop {
  src?: string
  alt?: string
  voiceWavSize?: number
  imgSize?: number
  className?: string
}

function VoiceWaveHeadShot({
  src,
  alt,
  className,
  voiceWavSize = 52,
  imgSize = 36,
}: Prop) {
  return (
    <div
      className={cn('relative flex items-center justify-center', className)}
      style={{ height: voiceWavSize, width: voiceWavSize }}
    >
      {src && (
        <img
          src={src}
          alt={alt}
          className={`rounded-full`}
          style={{ height: imgSize, width: imgSize }}
        />
      )}

      {!src && (
        <div
          className="bg-glass-separators rounded-full"
          style={{ height: imgSize, width: imgSize }}
        />
      )}
      <img
        src="/images/voice_wave_thinking_black.gif"
        alt="arrow-right"
        className="absolute left-0 top-0 size-full"
      />
    </div>
  )
}

export default VoiceWaveHeadShot
