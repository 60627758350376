'use client'

import { ROUTE_PATH } from 'config/routerPath'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useSuggestAvatarsStore } from 'store/suggest-avatars-sotre'
import SuggestSkeleton from './SuggestSkeleton'

const Suggested = () => {
  const t = useTranslations()
  const suggested = useSuggestAvatarsStore.use.avatars()
  const loading = useSuggestAvatarsStore.use.loading()

  return (
    <div className="scrollbar-none h-dvh w-full overflow-y-auto overscroll-contain py-5">
      <h2 className="text-title mb-5 pl-5">{t('avatar_suggest_title')}</h2>
      {loading ? (
        <div className="">
          {Array(12)
            .fill(null)
            .map((_, index) => (
              <SuggestSkeleton key={index} />
            ))}
        </div>
      ) : (
        <div className="flex flex-col">
          {suggested.map((avatar, index) => (
            <Link
              key={`${avatar.id}_${index}`}
              href={`${ROUTE_PATH.SIDEBAR.FEED}/${avatar.id}`}
              className="hover:bg-background-2nd flex items-center gap-2 py-[10px] pl-[14px]"
            >
              <div className="relative flex size-[52px] items-center justify-center">
                <img
                  src={avatar.head_shot_url}
                  alt={avatar.name}
                  className="size-[36px] rounded-full"
                />
                <img
                  src="/images/voice_wave_thinking_black.gif"
                  alt="thinking"
                  className="absolute left-0 top-0 size-full"
                />
              </div>
              <div className="flex min-w-0 flex-1 flex-col">
                <p className="text-callout text-l-l1 flex min-w-0 items-center">
                  <span className="min-w-0 shrink truncate">{avatar.name}</span>
                  {/* TODO: V2.2 再透過綁定帳號判斷是否顯示 subscription logo  */}
                  {false && (
                    <img
                      src="/images/plan_badge_icon.png"
                      alt="plan badge"
                      className="size-[20px] shrink-0"
                    />
                  )}
                </p>
                <p className="text-body text-l-l2 truncate">
                  {avatar.profession_type}
                </p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default Suggested
