'use client'
import { cn } from '@paladise/ui/lib/utils'
import { ROUTE_PATH } from 'config/routerPath'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React from 'react'

function MobileNav() {
  const pathname = usePathname()

  const LINKS = [
    {
      href: ROUTE_PATH.SIDEBAR.FEED,
      tabIcon: 'i-ps-user_s_big group-data-[active="true"]:i-ps-user_f_big',
    },
    {
      href: ROUTE_PATH.SIDEBAR.DISCOVER,
      tabIcon: 'i-ps-search_s_big group-data-[active="true"]:i-ps-search_f_big',
    },
    {
      href: ROUTE_PATH.SIDEBAR.MESSAGE,
      tabIcon: 'i-ps-mail_s_big group-data-[active="true"]:i-ps-mail_f_big',
    },
  ] as const

  return (
    <div className="min768:hidden fixed bottom-0 left-0 right-0 grid h-12 w-full grid-cols-3 bg-white">
      {LINKS.map(el => (
        <Link
          key={el.href}
          href={el.href}
          data-active={pathname.startsWith(el.href)}
          className="group flex items-center justify-center"
        >
          <span
            className={cn('mx-auto shrink-0 [--icon-size:32px]', el.tabIcon)}
            aria-hidden
          />
        </Link>
      ))}
    </div>
  )
}

export default MobileNav
