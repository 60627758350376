import { Skeleton } from '@paladise/ui/components/ui/skeleton'
import VoiceWaveHeadShot from 'components/avatar/VoiceWaveHeadShot'
import React from 'react'

function SuggestSkeleton() {
  return (
    <div className="flex p-3">
      <VoiceWaveHeadShot voiceWavSize={48} imgSize={36} className="mr-2" />
      <div>
        <Skeleton className="bg-glass-separators h-[18px] w-[100px]" />
        <Skeleton className="bg-glass-separators my-2 h-[18px] w-[200px]" />
        <Skeleton className="bg-glass-separators h-[18px] w-[100px]" />
      </div>
    </div>
  )
}

export default SuggestSkeleton
