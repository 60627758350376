'use client'

import { Button } from '@paladise/ui/components/ui/button'
import { cn } from '@paladise/ui/lib/utils'
import Logo from 'components/Logo'
import { appMobileMenu } from 'config/constants'
import { ROUTE_PATH } from 'config/routerPath'
import { logout } from 'features/auth/actions/logout'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import React from 'react'
import { usePopupStore } from 'store/popup-store'
import { session } from 'store/server-context/static'
import MobileMenu from './MobileMenu'

const MobileHeader = ({ className }: { className?: string }) => {
  const t = useTranslations()
  const [isLoading, startTransition] = React.useTransition()
  const setAuthPopup = usePopupStore.use.setAuthPopup()

  const menuLinks = appMobileMenu(t, session)

  return (
    <>
      <header
        className={cn(
          'min768:hidden z-header bg-background-1st h-mobile-fixed-top fixed left-0 right-0 top-0 flex w-full items-center justify-between px-4',
          className,
        )}
      >
        <MobileMenu
          menuLinks={menuLinks}
          additionalContent={onClose =>
            !session.guest && (
              <Button
                variant="label-l1"
                onClick={() => {
                  startTransition(async () => {
                    await logout()
                    onClose()
                  })
                }}
                disabled={isLoading}
                className="absolute bottom-[62px] left-0 right-0 flex h-12 items-center px-4 text-lg font-semibold"
              >
                {t('log_out')}
                <span
                  className={cn(
                    'ml-auto block size-[18px]',
                    isLoading
                      ? 'i-ps-cell_loading animate-spin'
                      : 'i-ps-chevron_right',
                  )}
                />
              </Button>
            )
          }
        />
        <Link
          href={ROUTE_PATH.SIDEBAR.FEED}
          className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2"
        >
          <Logo />
        </Link>

        {session.guest ? (
          <Button
            onClick={() => {
              setAuthPopup(true)
            }}
            className="bg-background-palup-white border-label-l3 text-quote text-label-l1 flex h-[34px] w-[65px] items-center justify-center rounded-full border p-0"
          >
            {t('login')}
          </Button>
        ) : (
          <div />
        )}
      </header>
    </>
  )
}

export default MobileHeader
